import {api} from 'services/api';
import {AxiosRequestConfig} from 'axios';
import {ContactUsData} from 'app/neom/types';

export const NeomService = {
  apiGetUsersStatistic: (config?: AxiosRequestConfig) =>
    api.get('utils/users-statistics', config),
  apiGetFreelancersList: (config: AxiosRequestConfig) =>
    api.get(`search/freelancers-search/?avatar_file_type=avatar`, config),

  apiGetFavoriteFreelancer: (freelancerId: number) =>
    api.get(`production_favorite/${freelancerId}`),
  apiPostFavoriteFreelancer: (id: number) =>
    api.post('production_favorites', [id]),
  apiDeleteFavoriteFreelancer: (id: number) =>
    api.delete(`production_favorite/${id}`),

  apiContactUs: (data: ContactUsData) =>
    api.post('notification/contact-us', {}, {params: data}),

  apiPostEmailDisplay: () => api.post('event/email-display'),
};
