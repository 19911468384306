import React from 'react';
import {useRouter} from 'next/router';
import {Layout, Props as LayoutProps} from 'components/layout';

export function HomeLayout({meta, children, isAuthRequired}: LayoutProps) {
  const router = useRouter();
  const role = router.query.role;

  return (
    <Layout meta={meta} isAuthRequired={isAuthRequired}>
      <div className="w-full max-w-[90rem] py-9 grow flex md:flex-col-reverse md:pt-0 md:grow-0 xl:py-9 xl:m-auto xl:flex-row">
        <div className="w-full flex flex-col max-w-none xl:max-w-[44rem]">
          {children}
        </div>

        <div className="hidden h-[19rem] items-end justify-center overflow-hidden w-full md:flex xl:block xl:ml-[-42px] xl:h-auto">
          <img
            src={
              role === 'production'
                ? '/home/homeBgProduction@2x.png'
                : '/home/homeBgFreelancer@2x.png'
            }
            alt="Home"
            className="w-full max-w-none xl:w-[47.5rem]"
          />
        </div>
      </div>
    </Layout>
  );
}
