import React, {useCallback, useEffect, useState} from 'react';
import NextLink from 'next/link';
import {getCurrentLocale} from 'services/locale';
import {homeContent} from 'app/neom/home/locale';
import {useUser} from 'context/user';
import {Button} from 'components/button';
import {formatWhitespace} from 'services/number';
import {NeomService} from 'app/neom/services';
import {calculateFreelancersCount} from 'services/freelancersCount';

export function Home() {
  const content = homeContent[getCurrentLocale()];

  const user = useUser().user;

  const [freelancersCount, setFreelancersCount] = useState(0);

  const getFreelancersCount = useCallback(async () => {
    const response = await NeomService.apiGetUsersStatistic();
    const count = calculateFreelancersCount(
      response.data?.number_of_approved_freelancers
    );

    setFreelancersCount(count);
  }, []);

  useEffect(() => {
    getFreelancersCount();
  }, [getFreelancersCount]);

  // user has Production role
  const checkFreelancersNode = user?.is_production && (
    <NextLink href="/freelancers">
      <a className="block mt-8 max-w-[17rem] mx-auto md:mt-14 xl:hidden">
        <Button color="secondary">{content.checkFreelancers}</Button>
      </a>
    </NextLink>
  );

  const getStatisticNode = (count: number, title: string) => (
    // ----------------------
    // Counter of freelances may be needed in the future (hidden for now)
    // ----------------------
    <div className="hidden">
      <h3 className="text-6xl leading-none font-light text-white mb-2 md:mb-4 md:text-[6.25rem]">
        {formatWhitespace(count)}
      </h3>
      <p className="text-base font-light tracking-wider uppercase text-alto md:text-xl">
        {title}
      </p>
    </div>
  );

  return (
    <div className="mt-4 text-center md:mt-9 xl:mt-4 xl:text-left">
      <h1 className="type-h1 mb-3 max-w-[18rem] mx-auto md:mx-0 md:max-w-none">
        {content.title}
      </h1>

      <p className="text-sm font-light text-alto tracking-wider mb-8 md:text-base">
        {content.description}
      </p>

      {getStatisticNode(freelancersCount, content.freelancersCvs)}

      {checkFreelancersNode}
    </div>
  );
}
