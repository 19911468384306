import React from 'react';

import styles from 'components/underlinedTitle/styles.module.css';

type Props = {
  title: string;
  subTitle?: string;
};

export function UnderlinedTitle(props: Props) {
  const {title, subTitle} = props;

  const titleClassNames = [
    styles.title,
    'z-10 mx-auto w-full font-normal text-2xl al text-white uppercase text-center xl:text-left relative pb-3 md:pb-4 md:pr-4 md:text-5xl md:mx-0 md:w-full',
  ].join(' ');

  return (
    <div>
      <div className={titleClassNames}>
        <h1>{title}</h1>

        {subTitle && (
          <h3 className="text-sm md:text-2xl font-light text-tussock mt-3 tracking-wider">
            {subTitle}
          </h3>
        )}
      </div>
    </div>
  );
}
