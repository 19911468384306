import React from 'react';
import {Home} from 'app/neom/home';
import {Meta} from 'components/meta';
import {WelcomeLayout} from 'components/layout/welcome';

function IndexPage() {
  return (
    <WelcomeLayout
      meta={<Meta title="Home" description="Changing the future of media" />}
      isAuthRequired
    >
      <Home />
    </WelcomeLayout>
  );
}

export default IndexPage;
