export const formatWhitespace = (value: number) => {
  const formatter = Intl.NumberFormat('en-US', {
    style: 'decimal',
    useGrouping: true,
  });

  return formatter
    .formatToParts(value)
    .filter(({type}) => type === 'integer')
    .map(({value}) => value)
    .join(' ');
};
