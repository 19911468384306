import React, {ReactNode} from 'react';
import {HomeLayout} from 'components/layout/home';
import {welcomeLayoutContent} from 'components/layout/welcome/locale';
import {getCurrentLocale} from 'services/locale';
import {UnderlinedTitle} from 'components/underlinedTitle';
// import {useRouter} from 'next/router';

type WelcomeLayoutProps = {
  meta: ReactNode;
  children: ReactNode;
  isAuthRequired: boolean;
};

export function WelcomeLayout({
  meta,
  children,
  isAuthRequired,
}: WelcomeLayoutProps) {
  const content = welcomeLayoutContent[getCurrentLocale()];

  // const router = useRouter();

  // const role = router.query.role;
  // const pathname = router.pathname;

  // const subTitle =
  //   role === 'production' && pathname === '/register'
  //     ? content.subTitleProduction
  //     : content.subTitle;

  return (
    <HomeLayout meta={meta} isAuthRequired={isAuthRequired}>
      <div className="w-full max-w-[30rem] mx-auto px-4 relative md:px-0 md:mt-[-107px] xl:mt-0 xl:ml-auto xl:mr-0 xl:pt-2">
        <UnderlinedTitle title={content.title} />

        <div className="w-full max-w-[26rem] mx-auto xl:mx-0">{children}</div>
      </div>
    </HomeLayout>
  );
}
